@page {
	margin: 0;
}

.fz4 {
	font-size: 0.25rem;
}

.fz8 {
	font-size: 0.5rem;
}

.fz6 {
	font-size: 0.375rem;
}

.fz10 {
	font-size: 0.625rem;
}

.fz12 {
	font-size: 0.75rem;
}

.fz14 {
	font-size: 0.875rem;
}

.fz18 {
	font-size: 1.125rem;
}

.fz20 {
	font-size: 1.25rem;
}

.fz22 {
	font-size: 1.375rem
}

.fz24 {
	font-size: 1.5rem
}

.fz26 {
	font-size: 1.625rem
}

.fz30 {
	font-size: 1.875rem
}

.price-tag-border {
	border: 1px solid #d1d1d1;
}

/* PRICE TAG 60x40 */
.price_tag_body_60x40 {
	color: #000;
	position: relative;
	border-radius: 5px;
	font-family: Arial, Helvetica, sans-serif;
	height: 146px;
	width: 230px;
	margin-right: 1rem !important;
	cursor: pointer;
	line-height: normal;
	overflow: hidden;
}

.price_tag_body_60x40_1_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 19px;
	font-size: 12px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.price_tag_body_60x40_1_price_left {
	font-size: 80px;
	font-weight: 700;
}

.price_tag_body_60x40_1_price_right {
	font-size: 30px;
	font-weight: 700;
	margin-top: -5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.price_tag_body_60x40_1_barcode {
	margin-top: -5px;
}

.price_tag_body_60x40_1_price {
	height: 73px;
}

/* PRICE TAG 60x30 */
.price_tag_body_60x30 {
	color: #000;
	position: relative;
	border-radius: 5px;
	font-family: Arial, Helvetica, sans-serif;
	height: 110px;
	width: 230px;
	margin-right: 1rem !important;
	cursor: pointer;
	line-height: normal;
	overflow: hidden;
}

.price_tag_body_60x30_1_pos {
	height: 7px;
}

.price_tag_body_60x30_1_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 34px;
	font-size: 14px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-bottom: -2px;
}

.price_tag_body_60x30_7_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 27px;
	font-size: 12px;
	line-height: 13.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.price_tag_body_60x30_7_pos {
	height: 20px;
	overflow: hidden;
}

.price_tag_body_60x30_8_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 19px;
	font-size: 12px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.price_tag_body_60x30_8_price_left {
	font-size: 60px;
	font-weight: 700;
}

.price_tag_body_60x30_8_price_right {
	font-size: 20px;
	font-weight: 700;
	margin-top: -5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.price_tag_body_60x30_8_barcode {
	margin-top: -5px;
}

.price_tag_body_60x30_8_price {
	height: 42px;
}

.price_tag_body_60x30_1_price {
	height: 27px;
	margin-bottom: -9px;
}

.price_tag_body_60x30_2_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 34px;
	font-size: 14px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-bottom: -2px;
}

.price_tag_body_60x30_2_price {
	height: 27px;
	margin-bottom: -9px;
}

.price_tag_body_60x30_3_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 34px;
	font-size: 14px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-bottom: -2px;
}

.price_tag_body_60x30_3_price {
	height: 27px;
	margin-bottom: -5px;
}

.price_tag_body_60x30_4_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 29px;
	font-size: 12px;
	line-height: 14.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-bottom: -2px;
}

.price_tag_body_60x30_4_price {
	height: 27px;
	margin-bottom: -8px;
}


.price_tag_body_60x30_10_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 34px;
	font-size: 14px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-bottom: -2px;
}

.price_tag_body_60x30_10_price {
	height: 48px;
	margin-bottom: -5px;
}

.price_tag_img.second img {
	top: 68px;
}

.price_tag_img.second input[type="file"] {
	width: 40px;
	height: 40px;
	top: 68px;
}

/* PRICE TAG 60x30 */

/* PRICE TAG 40x30 */
.price_tag_body_40x30 {
	color: #000;
	position: relative;
	border-radius: 5px;
	font-family: Arial, Helvetica, sans-serif;
	height: 110px;
	width: 152px;
	cursor: pointer;
	line-height: normal;
	overflow: hidden;
}

.price_tag_body_40x30_1_pos {
	height: 7px;
}

.price_tag_body_40x30_1_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 34px;
	font-size: 14px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-bottom: -5px;
}

.price_tag_body_40x30_1_price {
	height: 27px;
	margin-bottom: -9px;
}

.price_tag_body_40x30_2_pos {
	height: 7px;
}

.price_tag_body_40x30_2_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 34px;
	font-size: 14px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-bottom: -5px;
}

.price_tag_body_40x30_2_price {
	height: 55px;
	margin-bottom: -9px;
}

/* PRICE TAG 40x30 */

/* PRICE TAG 30x20 */
.price_tag_body_30x20 {
	color: #000;
	position: relative;
	border-radius: 5px;
	font-family: Arial, Helvetica, sans-serif;
	width: 140px;
	height: 90px;
	cursor: pointer;
	line-height: normal;
	overflow: hidden;
}

.price_tag_30x20_1_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 25px;
	font-size: 12px;
	line-height: 13.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.price_tag_30x20_1_price {
	height: 25px;
	margin-bottom: -9px;
}

.price_tag_30x20_2_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 33.6px;
	font-size: 14px;
	line-height: 17.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.price_tag_30x20_2_price {
	height: 35px;
	margin-bottom: -9px;
}

.price_tag_30x20_3_name {
	display: -webkit-inline-box;
	text-align: center;
	height: 25px;
	font-size: 12px;
	line-height: 13.3px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.price_tag_30x20_3_price {
	height: 35px;
	margin-bottom: -9px;
}

/* PRICE TAG 30x20 */

.price-border {
	border-bottom: 1px solid #000;
}

.h55 {
	height: 55px;
}

dl,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
ul {
	margin: 0;
}

td {
	vertical-align: top;
}

.text-break-spaces {
	white-space: break-spaces;
}

.del {
	text-decoration: line-through;
}

.price {
	margin-bottom: -9px;
}

.fw-700 {
	font-weight: 700;
}

.product_name {
	display: flex;
	justify-content: center;
}

.vertical-rl {
	left: 0;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
}

.vertical-between,
.vertical-end {
	display: flex;
	flex-direction: column;
}

.price_tag_img img {
	position: absolute;
	left: 20px;
	top: 58px;
	cursor: pointer;
}

.price_tag_img input[type="file"] {
	cursor: pointer;
	width: 50px;
	height: 50px;
	position: absolute;
	left: 20px;
	top: 58px;
	opacity: 0;
	z-index: 100;
}

.price_tag_discount {
	position: absolute;
	left: 5px;
	top: 55px;
	text-decoration: line-through;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.cashbox-table-danger-icon:hover {
	color: #fff;
}

.date {
	position: absolute;
	bottom: 0;
	right: 5px;
}

.date-top-right {
	position: absolute;
	top: 0;
	right: 5px;
}

.printing {
	right: 8px;
	bottom: 10px;
}

.product_name_20x30 {
	text-align: center;
	margin-bottom: -2px;
}

.price-tag-price-wrapper {
	position: relative;
}

.price-tag-price-wrapper div {
	margin: 5px 7px 5px 5px;
}

.price-tag-throw-line {
	position: absolute;
	width: 100%;
	margin: 0;
	top: 14px;
	color: #000;
	transform: rotate(-15deg);
	right: 4px;
	height: 2px !important;
	opacity: 1;
}

/* NEW */
@media print {
	@page {
		size: auto;
		margin: 0mm;
		padding: 0mm;
		float: none;
	}

	.page-breaker {
		page-break-after: always;
		overflow: hidden !important;
	}
}